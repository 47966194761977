<template>
    <div>
        <form-login-component/>
    </div>
    
</template>

<script>
    import FormLoginComponent from "@/components/auth/FormLoginComponent.vue";

    export default {
        name: "FormLogin",
        title: "Autenticación | Turismo BC",
        components: {
            FormLoginComponent,
        }
    };
</script>
